<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="gethelpText">
        <i class="fas fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'cost-help-spa',
    	props: {

    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = 'Zusätzliche Informationen';

    			return titleText;
    		},
    		gethelpText: function() {
    			//console.log(this.pricelist);
    			var helpText = 'Verwöhnen Sie sich mit unserem exklusiven SPA & Dining Angebot. Für nur 79,00 EUR pro Person erleben Sie pure Entspannung und kulinarischen Genuss. Nach einer 4-stündigen Verwöhnzeit im SPA ab 14:00 Uhr, erwartet Sie ab 18:00 Uhr ein köstliches Abendessen im angrenzenden Restaurant Kutter Kitchen. Dieses besondere Angebot ist von Sonntag bis Donnerstag buchbar und erfordert eine Reservierung.';
    			return helpText;
    		}
    	}
    };
</script>