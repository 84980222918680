<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="gethelpText">
        <i class="fas fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'cost-help-weeklycard',
    	props: {

    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = 'Zusätzliche Informationen';

    			return titleText;
    		},
    		gethelpText: function() {
    			//console.log(this.pricelist);
    			var helpText = 'Erleben Sie eine Woche voller Entspannung und Fitness für nur 69 EUR pro Person. Mit unserer Wochenkarte haben Sie 7 zusammenhängende Tage (ausgenommen samstags) Zutritt zu unseren erstklassigen Einrichtungen. Genießen Sie Sauna, Pool, Fitness und eine Vielzahl von Kursen – alles während unserer großzügigen Öffnungszeiten von 10 bis 20 Uhr. Selbstverständlich sind unsere luxuriösen SPA-Handtücher inklusive.';
    			return helpText;
    		}
    	}
    };
</script>