<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="gethelpText">
        <i class="fas fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'cost-help-morningspecial',
    	props: {

    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = 'Zusätzliche Informationen';

    			return titleText;
    		},
    		gethelpText: function() {
    			//console.log(this.pricelist);
    			var helpText = 'Starten Sie Ihren Tag mit einem erholsamen Besuch im SPARADISE. Unser Vormittagsspecial bietet Ihnen für nur 25 EUR (statt 30 EUR) pro Person Zugang zu Sauna, Pool und Fitnessbereich von 10 bis 14 Uhr. Unsere weichen SPA-Handtücher stehen Ihnen ebenfalls zur Verfügung, damit Ihr Besuch rundum angenehm wird.';
    			return helpText;
    		}
    	}
    };
</script>